import Image from 'next/image'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { mutate } from 'swr'
import { SITE_KEY } from '../Recaptcha'

import appScreenshotImage from '../../public/screenshot-monitors-list.png'
import useCurrentAccount from '../context/useCurrentAccount'

export default function HeadingWithCta() {
    const router = useRouter()
    const { account } = useCurrentAccount();
    const [email, setEmail] = useState('')
    const [url, setUrl] = useState('')
    const [registering, setRegistering] = useState(false)

    const onFormSubmit = useCallback((e: any) => {
        e.preventDefault()

        if (!email || !url || registering) return;

        setRegistering(true)

        grecaptcha.ready(function () {
            grecaptcha.execute(SITE_KEY, { action: 'create_account_with_url' }).then(function (recaptcha) {
                fetch('/web_api/accounts',
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ email, url, recaptcha })
                    })
                    .then(resp => resp.json())
                    .then(json => {
                        // console.log({ json });
                        if (json.status == 'ok') {
                            router.push('/accounts/activate');
                        } else if (json.status == 'already_registered') {
                            alert('You already have an account. Please, log in.');
                            router.push('/login')
                        } else if (json.status == 'error') {
                            alert('Error: ' + json.error)
                        }
                    }).catch(e => {
                        alert('Error: ' + e);
                        console.error(e)
                    })
                    .finally(() => setRegistering(false))
            })
        })
    }, [email, registering, router, url])

    return (
        <div id='head' className="bg-slate-900 pt-4 sm:pt-10 lg:overflow-hidden lg:pt-2 lg:pb-14">
            <div className="mx-auto max-w-7xl lg:px-8">
                <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                    <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
                        <div className="lg:py-24">
                            {/* <a
                                            href="#"
                                            className="inline-flex items-center rounded-full bg-black p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base"
                                        >
                                            <span className="rounded-full bg-gradient-to-r from-teal-500 to-cyan-600 px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                                                We're hiring
                                            </span>
                                            <span className="ml-4 text-sm">Visit our careers page</span>
                                            <ChevronRightIcon className="ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                                        </a> */}
                            <h1 className="mt-4 text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                {/* <span className="block">Free Uptime&nbsp;</span>
                                <span className="block bg-gradient-to-r from-teal-200 to-cyan-400 bg-clip-text pb-3 text-transparent sm:pb-5">
                                    Monitoring Tool
                                </span> */}
                                <span className="block"><a target='_blank' rel='noreferer' className='underline hover:no-underline' href='https://blog.uptime-monitor.io/benchmarking-alert-speeds-in-uptime-monitoring-tools-40548a729bc5'>Fast</a> and Reliable&nbsp;</span>
                                <span className="block bg-gradient-to-r from-teal-200 to-cyan-400 bg-clip-text pb-3 text-transparent sm:pb-5">
                                    Website Uptime Monitoring
                                </span>
                            </h1>
                            {
                                account ? (
                                    <div className='mt-8'>
                                        <button
                                            onClick={() => router.push('/dashboard')}
                                            className={`block w-full rounded-md bg-gradient-to-r from-teal-500 to-cyan-600 py-3 px-4 font-medium text-white shadow hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900 ${registering && 'animate-pulse'}`}
                                        >
                                            Open My Dashboard
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                                            {/* React on servers downtime faster. */}
                                            {/* <br /> */}
                                            {/* Let Uptime Monitor take care of your websites. */}
                                            {/* Everyone fails. */}
                                            Get 10 multi-region 30-second checks for free.
                                            <br />
                                            No credit card required.
                                            {/* Do not miss downtimes anymore. Track uptime of your services and show it to your customers. */}
                                        </p>

                                        <div className="mt-10 sm:mt-12">
                                            <form action="" className="sm:mx-auto sm:max-w-xl lg:mx-0" onSubmit={onFormSubmit}>
                                                <div className="mt-3 sm:mt-0 flex-1">
                                                    <label htmlFor="url" className="sr-only">
                                                        URL of your website
                                                    </label>
                                                    <input
                                                        id="url"
                                                        type="url"
                                                        value={url}
                                                        onChange={(e) => setUrl(e.target.value)}
                                                        required
                                                        placeholder="https://your-website.com"
                                                        className="block w-full rounded-md border-0 px-4 py-3 text-base bg-white text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900"
                                                    />
                                                </div>

                                                <div className="mt-3 sm:flex">
                                                    <div className="min-w-0 flex-1">
                                                        <label htmlFor="email" className="sr-only">
                                                            Your email address
                                                        </label>
                                                        <input
                                                            id="email"
                                                            type="email"
                                                            required
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            placeholder="your@email.com"
                                                            className="block w-full rounded-md border-0 px-4 py-3 text-base bg-white text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900"
                                                        />
                                                    </div>
                                                    <div className="mt-3 sm:mt-0 sm:ml-3">
                                                        <button
                                                            type="submit"
                                                            disabled={registering}
                                                            className={`block w-full rounded-md bg-gradient-to-r from-teal-500 to-cyan-600 py-3 px-4 font-medium text-white shadow hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900 ${registering && 'animate-pulse'}`}
                                                        >
                                                            Start monitoring
                                                        </button>
                                                    </div>
                                                </div>
                                                <p className="mt-3 text-sm text-gray-300 sm:mt-4">
                                                    We need to verify your email address to start monitoring.
                                                    {/* Uptime Monitor does not send spam or marketing emails. */}
                                                    {/* Start your free 14-day trial, no credit card necessary. */}
                                                    {/* By providing your email, you agree to
                                        our{' '}
                                        <a href="#" className="font-medium text-white">
                                            terms of service
                                        </a>
                                        . */}
                                                </p>
                                            </form>
                                        </div>
                                    </>
                                )
                            }

                        </div>
                    </div>
                    <div className="mt-12 -mb-16 sm:-mb-48 lg:relative lg:m-0">
                        <div className="mx-auto max-w-md sm:max-w-2xl sm:px-6 lg:max-w-none">
                            <Image
                                className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none rounded-lg"
                                src={appScreenshotImage}
                                alt="Uptime Monitor Dashboard"
                                priority
                            // placeholder='blur'
                            // fill
                            // width={827}
                            // height={604}
                            />
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
